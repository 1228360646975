<template>
  <div class="affiliation-form-container">
    <div class="banking-information-affiliation">
      <p
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
      >
        Banking Information
      </p>
      <input
        type="text"
        placeholder="IBAN"
        v-model="bankInformation.IBANCode"
      /><br /><br />
      <input
        type="text"
        placeholder="Bank Code"
        v-model="bankInformation.bankCode"
      />
      <br /><br />
      <input
        type="text"
        placeholder="BIC Code"
        v-model="bankInformation.BICCode"
      /><br /><br />
      <input
        type="text"
        placeholder="Owner"
        v-model="bankInformation.owner"
      /><br />
      <button
        type="button"
        class="save-button"
        :disabled="checkForm"
        @click="generateAffiliation()"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "affiliationForm",
  props: {
    tourAgency: String,
    information: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      bankInformation: {
        IBANCode:
          (this.information.bankInformation &&
            this.information.bankInformation.IBANCode) ||
          "",
        bankCode:
          (this.information.bankInformation &&
            this.information.bankInformation.bankCode) ||
          "",
        BICCode:
          (this.information.bankInformation &&
            this.information.bankInformation.BICCode) ||
          "",
        owner:
          (this.information.bankInformation &&
            this.information.bankInformation.owner) ||
          "",
      },
    };
  },
  computed: {
    checkForm: function () {
      if (this.IBANCode == "") {
        return true;
      }
      return false;
    },
  },
  methods: {
    generateAffiliation: function () {
      let data = { bankInformation: this.bankInformation };
      if (this.tourAgency) {
        data["affiliationType"] = "tourAgency";
        data["tourAgency"] = this.tourAgency;
      }
      if (this.information.hasOwnProperty("id")) {
        HandleApi.putAffiliation(this.information.id, data)
          .then(() => {
            this.$store.dispatch(
              "push_notification_message",
              this.$t("notification.updated", {
                object: this.$t("notification.affiliation_link"),
              })
            );
            this.$router.push("/affiliation/" + this.information.id);
          })
          .catch((err) => this.$store.dispatch("push_error_message", err));
      } else {
        HandleApi.postAffiliation(data)
          .then((affiliation) => {
            this.$store.dispatch(
              "push_notification_message",
              this.$t("notification.created", {
                object: this.$t("notification.affiliation_link"),
              })
            );
            this.$router.push("/affiliation/" + affiliation);
          })
          .catch((err) => this.$store.dispatch("push_error_message", err));
      }
    },
  },
};
</script>

<style scoped>
.affiliation-form-container {
  text-align: center;
}

.banking-information-affiliation {
  display: inline-block;
  margin: auto auto;
  text-align: center;
  border: 1px solid lightgrey;
  padding-bottom: 10px;
  border-radius: 10px;
}

.banking-information-affiliation p {
  margin: 0;
  padding: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  background-color: #00909f;
  border-radius: 10px 10px 0px 0px;
  text-align: left;
  margin-bottom: 20px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}
</style>
